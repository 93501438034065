@font-face {
    font-family:'Myriad Pro';
    src: url("./fonts/Myriad Pro Regular.ttf");
    font-style: normal;
    font-weight: 700;
}

@font-face {
    font-family: 'Acier BAT';
    src: url("./fonts/acier-bat-text-solid.otf");
    font-style: normal;
    font-weight: 700;
}

@font-face {
    font-family: 'Acier BAT outline';
    src: url("./fonts/acier-bat-text-outline.otf");
    font-style: normal;
    font-weight: 700;
}


@font-face {
    font-family: 'Inter';
    src: url("./fonts/inter-regular.ttf");
    font-style: normal;
    font-weight: 700;
}

@font-face {
    font-family: 'Inter Bold';
    src: url("./fonts/inter-bold.ttf");
    font-style: normal;
    font-weight: 700;
}

@font-face {
    font-family: 'Good Times';
    src: url("./fonts/GoodTimesHv-Regular.woff2");
    font-style: normal;
    font-weight: 700;
}


* {
    margin: 0px;
    padding: 0px;
}

*:focus {
    outline: none;
}

html, body {
    height: 100vh;
    background-color: black;
}

#root {
    max-width: 100%;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
